<template>
  <div class="e-dialogswrapper">
    <transition name="slide-to-left">
      <MessageList
        v-show="!dialogOpen && !messageEditorOpen"
        :items="dialogs"
      />
    </transition>

    <transition mode="out-in" name="dialog-container">
      <component :is="dialogComponent" :key="'dialog' + currentDialogId" />
    </transition>

    <div v-show="false" ref="messageContent">
      {{ $t('messages.user.yearOfBirth.missing.textPrepend') }}
      <router-link :to="{ name: 'account' }">
        {{ $t('messages.user.yearOfBirth.missing.link') }} </router-link
      >{{ $t('messages.user.yearOfBirth.missing.textAppend') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Dialogs from 'organisms/DialogWrapper/DialogWrapper'
import NewDialog from './NewDialog'
import MessageList from 'organisms/MessageList/MessageList'

export default {
  name: 'DialogTemplate',
  components: {
    Dialogs,
    MessageList,
    NewDialog
  },
  data() {
    return {
      dialogComponent: false
    }
  },
  computed: {
    ...mapGetters([
      'currentDialog',
      'currentDialogId',
      'currentRouteName',
      'dialogMessages',
      'dialogs',
      'dialogOpen',
      'messageEditorOpen',
      'userInfo'
    ]),
    dialogState() {
      return {
        dialogId: this.currentDialogId,
        currentRoute: this.currentRouteName
      }
    },
    newMessageEditorIsOpen() {
      return this.$route.name === 'newDialog'
    },
    dialogSelected() {
      return typeof this.currentDialogId !== 'undefined'
    }
  },
  watch: {
    dialogState() {
      this.setDialogVisibility()
    },
    dialogMessages: function(newVal) {
      if (
        newVal.length > 0 &&
        this.currentDialog &&
        this.currentDialog.draft &&
        this.currentDialog.threadCount === 1
      ) {
        this.setInitialEditMessage(this.dialogMessages[0])
        this.setEditMessage(this.dialogMessages[0])
      }
    },
    $route: function() {
      this.loadView()
    }
  },
  created: async function() {
    try {
      await this.getDialogs()
      this.setDialogVisibility()
      this.loadDialogs()
    } catch (e) {
      this.$error(e)
    } finally {
      this.setComponentName()

      // if (this.userInfo && !this.userInfo.yearOfBirth) this.openYearOfBirthMessage()
    }
  },
  destroyed() {
    this.setMessageEditorOpen(false)
    this.setDialogOpen(false)
  },
  methods: {
    ...mapActions([
      'getDialog',
      'getDialogs',
      'setDialogOpen',
      'setEditMessage',
      'setInitialEditMessage',
      'setMessageEditorOpen'
    ]),

    // openYearOfBirthMessage () {
    //   this.$message({
    //     type: 'warning',
    //     duration: 0,
    //     showClose: true,
    //     dangerouslyUseHTMLString: true,
    //     message: this.$refs.messageContent.innerHTML
    //   })
    // },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },

    setComponentName() {
      if (this.currentRouteName === 'dialog') {
        this.dialogComponent = 'dialogs'
      }
      if (this.currentRouteName === 'newDialog') {
        this.dialogComponent = this.currentRouteName
      }
    },

    async loadView() {
      const oldComponentName = this.dialogComponent
      this.dialogComponent = false

      if (oldComponentName !== false) await this.sleep(500) // wait for transition to end

      await this.loadDialogs()
      this.setComponentName()

      return true
    },

    async loadDialogs() {
      if (this.newMessageEditorIsOpen && !this.dialogSelected) {
        this.$store.commit('resetEditMessages')
      }

      if (this.dialogSelected) {
        await this.getDialog(this.currentDialogId)
      }
      return true
    },
    setDialogVisibility() {
      this.setDialogOpen(!this.newMessageEditorIsOpen && this.dialogSelected)
      this.setMessageEditorOpen(this.newMessageEditorIsOpen)
    }
  }
}
</script>
